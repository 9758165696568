export default {
	reload () {
		window.location.reload()
	},
	listenArrowKey (e) {
		const currentElement = document.activeElement
		const currentParent = currentElement?.closest('.focus-container')

		if (e.key === 'ArrowRight') {
			const nextParent = currentParent?.nextElementSibling || currentParent?.parentNode.closest('.focus-container')?.nextElementSibling
			const nextElement = nextParent?.getElementsByClassName('focus-element')[0]

			nextElement?.focus()
		} else if (e.key === 'ArrowLeft') {
			const previousParent = currentParent?.previousElementSibling || currentParent?.parentNode.closest('.focus-container')?.previousElementSibling || currentParent?.parentNode.closest('.focus-container')?.parentNode.closest('.focus-container')?.previousElementSibling
			const previousElement = previousParent?.getElementsByClassName('focus-element')[0]

			previousElement?.focus()
		}
	},
	focus (refName, selector) {
		if (refName && this.$refs[refName]) {
			this.$refs[refName].focus()
		} else if (selector) {
			document.querySelector(selector)?.focus()
		}
	},
	setTabsShortcuts () {
		document.getElementsByClassName('tab').forEach((t, i) => {
			t.childNodes[4].innerHTML = `Alt + ${i + 1}`
		})
	},
	getUniqueId (id = null, date = null) {
		date = date || new Date()

		const appVersionNumber = this.store?.getters.appVersionNumber || this.$store.getters.appVersionNumber

		if (appVersionNumber >= 3849) {
			return id ? id.toString() : `${date.valueOf()}.${date.getMilliseconds()}`
		} else {
			return id ? parseInt(id) : date.valueOf()
		}
	},
	checkObjectKey (keys) {
		let object = this.$store.state.settings

		keys.every((key) => {
			object = object ? object[key] : undefined

			return typeof object !== 'undefined'
		})

			return typeof object !== 'undefined' ? object : true
	},
	getDataURL (url) {
		return new Promise((resolve) => {
			const image = new Image()

			image.onload = function () {
				const canvas = document.createElement('canvas')

				canvas.width = this.naturalWidth
				canvas.height = this.naturalHeight
				canvas.getContext('2d').drawImage(this, 0, 0)
				resolve(canvas.toDataURL('image/png'))
			}

			image.src = `/api/image?r=${url}`
		})
	},
	getPriceFromBarcode (barcode) {
		if (barcode.length === 13) {
			return {
				barcode: barcode.substr(0, 7),
				price: parseFloat(barcode.substr(-6, 3) + '.' + barcode.substr(-3, 2))
			}
		} else if (barcode.length === 12) {
			return {
				barcode: barcode.substr(0, 7),
				price: parseFloat(barcode.substr(-5, 2) + '.' + barcode.substr(-3, 2))
			}
		}

		return null
	},
	getUpdatedAtDateTime (time) {
		if (!time) { return null }

		const day = new Date(time)

		day.setHours(day.getHours() - 1)

		return new Date(day.getTime() + day.getTimezoneOffset() * 60000)
			.toISOString().slice(0, 19).replace('T', ' ')
	},
	applyMultiPricing (variation, priceCategoryId) {
		const priceCategory = variation.price_category.find((priceCategory) => {
			return (priceCategory.category_id === priceCategoryId && (
				priceCategory.config === 'local' || priceCategory.type === 'item'
			)) || (
				(
					priceCategory.custom_attributes && priceCategory.custom_attributes.applicable_time_period === 'range' && this.$moment().isBetween(
						this.$moment(`${priceCategory.start_date} ${priceCategory.start_time}`),
						this.$moment(`${priceCategory.end_date} ${priceCategory.end_time}`)
					)
				) || (
					priceCategory.custom_attributes && priceCategory.custom_attributes.applicable_time_period === 'days' &&
					priceCategory.custom_attributes.days_of_week.includes(this.$moment().format('dddd').toLowerCase()) &&
					this.$moment().isBetween(this.$moment(priceCategory.start_time, 'hh:mm:ss'), this.$moment(priceCategory.end_time, 'hh:mm:ss'))
				)
			)
		})

		if (priceCategory) {
			variation.price_category_id = priceCategory.category_id || priceCategory.id
			variation.tax = priceCategory.tax

			if (priceCategory.price_type === 'percentage') {
				variation.price = variation.price +
					((priceCategory.price_value / 100) * variation.price)
			} else if (priceCategory.price_type === 'fixed') {
				variation.price += priceCategory.price_value
			}

			if (variation.price < 0) {
				variation.price = 0
			}
		}

		if (variation.batch) {
			variation.batch = variation.batch.map((batch) => {
				if (priceCategory.price_type === 'percentage') {
					batch.price =
					batch.price + (priceCategory.price_value / 100) * batch.price
				} else if (priceCategory.price_type === 'fixed') {
					batch.price += priceCategory.price_value
				}

				if (batch.price < 0) {
					batch.price = 0
				}

				return batch
			})
		}

		return variation
	},
	validateConditions (price, conditions) {
		for (const key in conditions) {
			// eslint-disable-next-line
			if (Object.prototype.hasOwnProperty.call(price, key) && conditions[key]?.length && !eval(conditions[key].map((condition) => {
				return `${price[key]} ${condition.operator} ${condition.value}`
			}).join(' && '))) {
				return false
			}
		}

		return true
	},
	groupBy (xs, key) {
		return xs.reduce((rv, x) => {
			(rv[x[key]] = rv[x[key]] || []).push(x)

			return rv
		}, {})
	},
	toFixed (num, fixed) {
		const arr = num.toString().match(new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?'))

		return arr?.length ? arr[0] : num
	},
	sleep (ms) {
		return new Promise(resolve => setTimeout(resolve, ms))
	},
	getDomain () {
		return `${window.location.protocol}//${window.location.hostname}${
			window.location.port ? (':' + window.location.port) : ''
		}`
	},
	convertCase (from, to, str) {
		switch (`${from}-${to}`) {
			case 'kebab-camel':
				str = str.replace(/-([a-z])/g, g => g[1].toUpperCase())
				break
			case 'kebab-snake':
				str = str.replace(/-/g, '_')
				break
			default:
				break
		}

		return str
	},
	getUnitMeasureType (unit) {
		return typeof unit === 'string' ? unit : unit?.short_name
	},
	getUnitDecimalPlaces (unit) {
		let decimalPlaces = 0

		unit = this.getUnitMeasureType(unit)

		switch (unit) {
			case 'count':
				decimalPlaces = 0
				break
			case 'weight':
			case 'volume':
			case 'length':
				decimalPlaces = 3
				break
		}

		return decimalPlaces
	},
	getWeight (setWeight) {
		const weighingScale = this.$store.state.weighingScale

		window.getWeight = setWeight

		// TODO: Remove after 2 releases
		if (this.$store.getters.appVersionNumber < 4010) {
			this.$bridge.getWeight(
				weighingScale.serialPortOptions.path,
				this.$store.state.bridgeName === 'ANDROID'
					? this.objToJson(weighingScale.serialPortOptions)
					: weighingScale.serialPortOptions
			)
		} else {
			this.$bridge.getWeight(
				this.$store.state.bridgeName === 'ANDROID'
					? this.objToJson(weighingScale.serialPortOptions)
					: weighingScale.serialPortOptions
			)
		}
	},
	evenly (numerator, denominator) {
		const pow10 = this.$currency.transformNumber(Math.pow(10, this.$currency.getCurrencyDecimalPlaces()))

		numerator = this.$currency.transformNumber(numerator * pow10)

		const divide = Math.floor(numerator / denominator)
		const remain = this.$currency.transformNumber(numerator - divide * denominator)
		const result = []
		let cnt = 0

		while (++cnt && cnt <= denominator) {
			result.push(this.$currency.transformNumber((cnt <= remain ? divide + 1 : divide) / pow10))
		}

		return result
	},
	filterItem (items, item, returnType) {
		const itemModifiers = item.groups
			? item.groups.filter(g => g.type === 'modifier').map(m => m.group_item_variation_id)
			: []
		const index = items.findIndex((i) => {
			const iModifiers = i.groups
				? i.groups.filter(g => g.type === 'modifier').map(m => m.group_item_variation_id)
				: []
			const iDynamicDiscount = Array.isArray(i.item_discount) && i.item_discount.find(i => i.entity === 'dynamic')
			const itemDynamicDiscount = Array.isArray(item.item_discount) && item.item_discount.find(i => i.entity === 'dynamic')
			const dynamicDiscountFlag = (!itemDynamicDiscount && !iDynamicDiscount) || (iDynamicDiscount && itemDynamicDiscount && iDynamicDiscount.get_discount_type === itemDynamicDiscount.get_discount_type &&
				iDynamicDiscount.get_discount_value === itemDynamicDiscount.get_discount_value)

			return (i.id || i.variation_id) === (item.id || item.variation_id) && i.batch_id === (item.batch_id || null) &&
				i.price === item.price && itemModifiers.filter(m => !iModifiers.includes(m)).length === 0 && dynamicDiscountFlag &&
				(!i.custom_attributes?.gift_card || i.custom_attributes.gift_card.id === item.custom_attributes?.gift_card?.id)
		})

		if (returnType === 'index') {
			return index
		} else {
			return index !== -1 ? items[index] : null
		}
	},
	async addOrderItemsToCart (order, newItems = [], reset = true) {
		if (reset) {
			this.$store.commit('resetCart')
		}

		const cartItems = []
		const itemDiscounts = this.$store.getters.discounts('item')
		const categoryDiscounts = this.$store.getters.discounts('category')
		const charges = []

		if (order.charges?.length) {
			let dbCharges = await this.$bridge.getCharges(
				this.deviceId,
				this.$store.state.locationId,
				this.objToJson({ id: order.charges.map(c => c.id) })
			)

			dbCharges = (typeof dbCharges === 'string' ? JSON.parse(dbCharges) : dbCharges).data

			order.charges.forEach((orderCharge) => {
				const charge = dbCharges.find(dbCharge => dbCharge.id === orderCharge.id)

				if (charge?.category === 'dynamic_charge') {
					charges.push({
						...charge,
						value: orderCharge.value,
						tax: orderCharge.taxes.map(tax => ({
							id: tax.tax_id,
							name: tax.tax_name,
							rate: tax.tax_rate,
							inclusion_type: tax.inclusion_type
						})),
						name: orderCharge.name,
						type: orderCharge.type,
						category: orderCharge.slug,
						applicable_on: orderCharge.applicable_on
					})
				} else if (charge) {
					charges.push(charge)
				}
			})
		}

		this.$store.commit('setState', { key: 'selectedDiscount', value: order.discounts[0] })

		if (order.tip) {
			this.$store.commit('setState', {
				key: 'tip',
				value: order.custom_attributes.tip || {
					type: 'fixed',
					value: order.tip
				}
			})
		}

		let dbItemVariations = await this.$bridge.getItemVariations(this.deviceId, this.objToJson({
			id: order.items.map(i => i.variation_id),
			offset: -1
		}))

		dbItemVariations = (typeof dbItemVariations === 'string' ? JSON.parse(dbItemVariations) : dbItemVariations)
		dbItemVariations = dbItemVariations.data || dbItemVariations
		order.items.forEach((item) => {
			let priceCategory = null
			const dbItemVariation = dbItemVariations.find(i => i.id === item.variation_id)
			let itemVariationDiscounts = itemDiscounts.filter((d) => {
				return d.discount_items.findIndex((i) => {
					return i.variation_id === item.variation_id && i.buy_condition_value
				}) !== -1
			})
			const itemCategoryDiscounts = categoryDiscounts.filter((d) => {
				d.entity = 'category'

				return d.discount_categories.findIndex((i) => {
					return i.category_id === item.category_id && i.buy_condition_value
				}) !== -1
			})

			itemVariationDiscounts = itemVariationDiscounts.concat(itemCategoryDiscounts)

			if (dbItemVariation) {
				if (order.price_category) {
					priceCategory = dbItemVariation.price_category.find(pc => pc.category_id === order.price_category.id)
				}

				cartItems.unshift({
					id: item.variation_id,
					item_id: item.item_id,
					category_id: item.category_id,
					inventory_id: item.inventory_id,
					batch_id: item.batch_id,
					brand_id: item.brand_id,
					kot_device_id: dbItemVariation.kot_device_id,
					item_code: item.item_code,
					name: item.variation_name,
					item_name: item.item_name,
					alternate_name: item.alternate_name,
					sku: dbItemVariation.sku,
					type: dbItemVariation.type,
					barcode: dbItemVariation.barcode,
					hsn: dbItemVariation.custom_attributes.hsn || '',
					unit_measure_type: item.unit_measure_type,
					mrp: parseFloat(dbItemVariation.custom_attributes.mrp || 0),
					price: parseFloat(item.price),
					quantity: item.quantity,
					tax: priceCategory
						? priceCategory.tax
						: (item.taxes.length
							? item.taxes.map((t) => {
								return {
									id: t.tax_id,
									name: t.tax_name,
									inclusion_type: item.tax_type === 'exclusive' ? 'additive' : 'inclusive',
									rate: t.tax_rate
								}
							})
							: dbItemVariation.tax),
					discount: item.discounts,
					item_discount: item.discounts.filter(d => Object.prototype.hasOwnProperty.call(d, 'type')),
					combo_discount: itemVariationDiscounts.reduce((discounts, discount) => {
						discount.discount_items.forEach((di) => {
							if (!(di.variation_id === item.variation_id && di.get_discount_quantity === 0 &&
								di.get_discount_type)) {
								discounts.push({
									...di,
									type: 'COMBO',
									id: discount.id,
									name: discount.name,
									entity: discount.entity
								})
							}
						})

						if (discount.discount_categories) {
							discount.discount_categories.forEach((di) => {
								if (di.category_id === item.category_id) {
									discounts.push({
										...di,
										type: 'COMBO',
										id: discount.id,
										name: discount.name,
										entity: discount.entity
									})
								}
							})
						}

						return discounts
					}, []),
					itemization_type: dbItemVariation.itemization_type || 'item',
					groups: item.groups,
					notes: item.notes,
					taxes: item.taxes,
					discountedAmount: item.discounted_amount,
					discountedTax: item.discounted_tax,
					discounts: item.discounts,
					custom_attributes: {
						...item.custom_attributes,
						...dbItemVariation.custom_attributes
					}
				})
			}
		})

		this.$store.commit('setCart', {
			priceCategory: order.price_category,
			table: order.table,
			waiter: order.created_by,
			items: cartItems,
			additionalInfo: order.custom_attributes.labels?.reduce((info, label) => {
				info[label.label.toLowerCase().split(' ').join('-')] = label.value

				return info
			}, {}) || {},
			ignoredCharges: order.custom_attributes?.ignoredCharges || [],
			charges
		})

		if (newItems.length) {
			newItems.forEach(async (item) => {
				const cartItemIndex = this.filterItem(cartItems, item, 'index')

				if (cartItemIndex !== -1 && order.tables.filter(
					t => this.$store.state.selectedTables.findIndex(st => st.id === t.id) === -1
				).length === 0) {
					item.quantity += cartItems[cartItemIndex].quantity
				}

				if (item.item_discount.length) {
					cartItems.unshift(item)
					this.$store.commit('setCart', { items: cartItems })
				} else {
					await this.$store.dispatch('modifyCart', {
						item: {
							id: item.item_id,
							category_id: item.category_id,
							name: item.item_name,
							brand_id: item.brand_id
						},
						variation: {
							id: item.id,
							inventory_id: item.inventory_id,
							kot_device_id: item.kot_device_id,
							batch_id: item.batch_id,
							name: item.name,
							sku: item.sku,
							type: item.type,
							unit_measure_type: item.unit_measure_type,
							price: item.price,
							quantity: item.quantity,
							tax: item.tax,
							itemization_type: item.itemization_type,
							groups: item.groups,
							notes: item.notes,
							custom_attributes: {
								alternate_language: item.alternate_name,
								hsn: item.hsn,
								mrp: item.mrp
							}
						},
						triggerCalculation: false
					})
				}
			})
		}

		await this.$store.dispatch('cartCalculation')
	},
	getTableStatusColor (table) {
		const tableColors = {
			occupied: 'danger',
			free: 'success',
			billed: 'warning',
			blocked: 'secondary'
		}

		return table.status
			? tableColors[table.status]
			: (table.is_occupied ? 'danger' : 'success')
	},
	objToJson (obj) {
		// eslint-disable-next-line no-control-regex
		return obj ? JSON.stringify(obj).replace(/[\u0000-\u001F\u007F]/g, ' ') : '{}'
	}
}
